"use client";

import React, { useState, MouseEvent } from "react";
import { Button } from "@/components/ui/button";
import ScoreInputModal from "@/components/Score/InputModal";
import { useAppContext } from "@/context/AppContextProvider";
import { useTranslation } from "@/lib/react/i18n";
import "./scoremodalButton.css";
import { ImageResponseData, ImageResponse } from "@/restapi/image";

const ScoreModalButton = ({ imageData, setScoreExists }: { imageData: ImageResponseData; setScoreExists: (value: boolean) => void; }) => {
  const [showModal, setShowModal] = useState(false);
  const { visableMode } = useAppContext();
  const { t } = useTranslation();

  const handleCommentClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowModal(true);
  };

  if (imageData.scoreExists) {
    return <></>;
  }
  return (
    <>
      <Button
        className={`scoring-btn ${visableMode.isShow ? "fade-in" : "fade-out"}`}
        variant="link"
        onClick={handleCommentClick}
      >
        {t("SCORING")}
      </Button>
      {showModal && (
        <ScoreInputModal
          imageResData={imageData}
          setScoreExists={setScoreExists}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default ScoreModalButton;
