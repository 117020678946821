'use client';

import { useTranslation } from '@/lib/react/i18n';
import Image from 'next/image';
import React from 'react';

interface UserIconProps {
	fileURL?: string;
	plusDisabledIcon?: boolean;
	size?: {
		width: number;
		height: number;
	}
	className?: string;
}

const UserIcon: React.FC<UserIconProps> = ({ fileURL, plusDisabledIcon, className, size = {
	width: 100,
	height: 100,
} }) => {
	const styles: React.CSSProperties = {
		position: 'absolute',
		bottom: '2px',
		right: '2px',
		width: size.width / 4,
		height: size.height / 4,
		backgroundColor: 'rgba(255, 255, 255, 0.3)',
		border: '1px solid #fff',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: size.width / 4,
		color: '#fff',
	};

	const { t } = useTranslation();

	return (
		<div
			className={className}
			style={{
				position: 'relative',
				width: size.width,
				height: size.height,
				backgroundColor: '#B0B0B0',
				borderRadius: '50%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				fontSize: (size.width / 6),
				color: '#000'
			}}>
			{fileURL ? (
				<Image
					className='object-cover'
					src={fileURL}
					alt=""
					width={size.width}
					height={size.height}
					style={{
						borderRadius: '50%',
						width: '100%',
						height: '100%',
					}}
					priority={true}
				/>
			) : (
				<span>{t('User')}<br />{t('Icon')}</span>
			)}
			{!plusDisabledIcon && <div style={styles}>+</div>}
		</div>
	);
};

export default UserIcon;
