import { URLConstants } from "@/constants/URLConstants"
import { useAppContext } from "@/context/AppContextProvider"
import { useIsFollowing } from "@/hooks/user/useFollow";
import { ImageResponseData } from "@/restapi/image";
import Link from "next/link";
import { usePathname } from "next/navigation";

export const FollowingTag = ({ image, userId }: { image: ImageResponseData, userId: string }) => {
  const pathname = usePathname();
  const { isMobile } = useAppContext();
  const {
    data,
    isLoading: followingLoading,
    isError: followingError,
  } = useIsFollowing({
    userId,
    followingUserId: image.userProfile.uuid,
  });

  if (followingLoading || userId.length < 1) {
    return <></>;

  }
  if (followingError) {
    return <></>;
  }

  return ((data?.following ?? false) ?
    <div
      className={`${pathname !== "/" ? "underline underline-offset-4" : ""
        }`}
    >
      <Link
        href={`${URLConstants.user.following.new_posts.pathname}/${image.userUuid}`}
        className={`${isMobile ? "text-sm" : "text-xl"}`}
        onClick={(e) => e.stopPropagation()}
      >
        following
      </Link>
    </div>
    : <></>
  )
}