"use client";

import useSWR, { mutate } from "swr";
import { UserAPI, UserScoreRequest } from "@/restapi/user";

export const useUserScore = () => {
  const submitScore = async (userScoreRequest: UserScoreRequest) => {
    try {
      // APIにPOSTリクエストを送信してデータを取得
      const newScoreData = await UserAPI.score(userScoreRequest);

      // キャッシュを更新
      mutate(
        `score-${userScoreRequest.imageId}-${userScoreRequest.userId
        }-${JSON.stringify(userScoreRequest)}`,
        newScoreData,
        false
      );

      return newScoreData;
    } catch (err) {
      console.error("Failed to submit score:", err);
      throw err; // エラー処理
    }
  };

  return {
    submitScore, // スコア送信関数を返す
  };
};
