"use client";

import React, { useEffect, useState } from "react";
import Image from "next/image";
import { useSession } from "next-auth/react";
import ScoreModalButton from "@/components/ScoreModalButton";
import { useAppContext } from "@/context/AppContextProvider";
import { bizUDPGothicBold } from "@/lib/utils/fonts";
import { usePathname } from "next/navigation";
import { BackendURLContantas, URLConstants } from "@/constants/URLConstants";
import { ImageResponseData } from "@/restapi/image";
import { useIsFollowing } from "@/hooks/user/useFollow";
import useEcho from "@/hooks/useEcho";
import { ScoreData } from "@/restapi/score";
import { ScorePostEventResponse } from "@/restapi/user";
import UserImageIcon from "@/components/icons/UserImageIcon";
import { ImageAverageModal } from "@/components/Pict/ImageAverageModal";
import Link from "next/link";
import "@/components/image/full-screen/fullScreenImage.css";
import { FollowingTag } from "./FollowingTag";


const FullScreenImage: React.FC<{ image: ImageResponseData }> = ({ image }) => {
  const { data: session } = useSession();
  const user = session?.user;
  const userId = user?.id ?? "";

  const [scoreExists, setScoreExists] = useState(image.scoreExists);

  const {
    isMobile,
    visableMode,
    setVisableMode,
    settingMenuProps,
    setSettingMenuProps,
  } = useAppContext();

  const [scoreData, setScoreData] = useState<ScoreData>({
    posts: image.posts ?? 0,
    averageScore: image.averageScore,
    totalScore: image.totalScore ?? 0,
  });

  const pathname = usePathname();

  const [isModalOpen, setIsModalOpen] = useState(false);

  // cpu負荷かかるのでいったんコメントアウト
  // const { item } = useImageById({
  //   imageUuid: image.uuid,
  //   authUuid: user?.id ?? '',
  // });

  // useEffect(() => {
  //   if (item) {
  //     setScoreData(pre => ({
  //       totalScore: item.totalScore ?? 0,
  //       averageScore: item.averageScore ?? null,
  //       posts: item.posts ?? 0,
  //     }));

  //     console.log('item');
  //     console.log(item.scoreExists);
  //     setScoreExists(item.scoreExists ?? false);
  //   }
  // }, [item]);

  // // 平均点リアルタイム反映
  // const echo = useEcho();
  // useEffect(() => {
  //   if (echo) {
  //     echo
  //       .channel(`channel-score-post-${image.uuid}`)
  //       .listen("ScorePostEvent", (response: ScorePostEventResponse) => {
  //         const {averageScore, totalScore, posts, userId: _userId } = response;
  //         setScoreExists(true);
  //         setScoreData({
  //           averageScore,
  //           totalScore,
  //           posts,
  //         });
  //       });
  //   }
  // }, [echo]);

  const handleClickAverage = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleToggleVisibility = () => {
    if (settingMenuProps.isMeneuOpen) {
      setSettingMenuProps({
        isMeneuOpen: false,
      });
      return;
    }

    setVisableMode({
      isShow: !visableMode.isShow,
    });
  };


  // if (isLoading || followingLoading) {
  //   return (<div className="flex flex-col items-center justify-center min-h-screen bg-black text-white p-4">
  //     <div className="mb-10 text-center">
  //       Loading...
  //     </div>
  //   </div>)
  // }

  return isModalOpen ? (
    <ImageAverageModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      image={image}
    />
  ) : (
    <div className="relative w-100 h-screen" onClick={handleToggleVisibility}>
      <div className="text-with-outline-shadow average-score">
        {typeof scoreData.averageScore === "number" &&
          scoreData.posts !== null && (
            <div
              onClick={handleClickAverage}
              className={`cursor-pointer avgScoreBox ${visableMode.isShow ? "fade-in" : "fade-out"
                }`}
            >
              <span className="avgScore">{scoreData.averageScore}</span>
              <span className="avgTxt">avg.</span>
            </div>
          )}
      </div>
      <Image
        className={`${visableMode.isShow ? "fade-in" : "fade-out"} object-cover`}
        alt={image.imageFile.fileName}
        src={image.imageFile.fileUrl}
        fill
        priority
      />
      <Image
        className={`${visableMode.isShow ? "fade-out" : "fade-in"} object-contain`}
        alt={image.imageFile.fileName}
        src={image.imageFile.fileUrl}
        fill
        priority
      />

      <div className="mb-16 scoringBtnArea">
        {user && (
          <>
            <div className="absolute top-3 left-1/2 transform -translate-x-1/2 flex justify-center space-x-4 text-white">
              <div
                className={`${pathname === "/" ? "underline underline-offset-4" : ""
                  }`}
              >
                <Link
                  href={BackendURLContantas.IMAGE.LATEST.PATH_NAME}
                  className={`${isMobile ? "text-sm" : "text-xl"}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  NEW
                </Link>
              </div>
              {session.user &&
                (
                  <FollowingTag
                    image={image}
                    userId={userId}
                  />
                )
              }
            </div>
            <div
              className={`
        absolute top-3 right-4
        ${visableMode.isShow ? "fade-in" : "fade-out"}`}
            >
              <UserImageIcon
                fileURL={image.userProfile?.userIconFile?.fileUrl ?? ""}
                plusDisabledIcon={true}
                size={{
                  width: 50,
                  height: 50,
                }}
              />
            </div>
          </>
        )}
        {!scoreExists && (
          <ScoreModalButton
            setScoreExists={setScoreExists}
            imageData={image} />
        )}
      </div>

      <div className="scoreInfoArea">
        <div
          className={`opacity-65 score-number ${bizUDPGothicBold.className} ${visableMode.isShow ? "fade-in" : "fade-out"
            }`}
        >
          <span className="opacity-65">{image.myScore}</span>
        </div>
        <>
          <div
            className={`mapMark"><LocateIcon className="text-with-outline-shadow locate-icon ${visableMode.isShow ? "fade-in" : "fade-out"
              }`}
          ></div>
          <div className="cityBtn">
            <span
              className={`text-white text-with-outline-shadow city-name ${visableMode.isShow ? "fade-in" : "fade-out"
                }`}
            >
              {image.city && image.province
                ? `${image.province} ${image.city}`
                : image.country}
            </span>
          </div>
          <div className="accountBtn">
            {(image.userProfile.name ?? null !== null) &&
              11 < image.userProfile.name.length ? (
              <span
                className={`text-white text-with-outline-shadow account-name ${visableMode.isShow ? "fade-in" : "fade-out"
                  }`}
              >
                @{`${image.userProfile.name.slice(0, 11)}...`}
              </span>
            ) : (
              <span
                className={`text-white text-with-outline-shadow account-name ${visableMode.isShow ? "fade-in" : "fade-out"
                  }`}
              >
                @{image.userProfile.name}
              </span>
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default FullScreenImage;
