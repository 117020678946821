"use client";

import { UserAPI, UserFollowRequest } from "@/restapi/user";
import APP_CONSTANTS from "@/constants/AppConstants";
import useSWR, { mutate } from "swr";

export const useUserFollowPost = (request: UserFollowRequest) => {
  const submit = async () => {
    try {
      // APIにPOSTリクエストを送信してデータを取得
      const newData = await UserAPI.follow(request);

      // キャッシュを更新
      mutate(`follow-${JSON.stringify(request)}`, newData, false);

      return newData;
    } catch (err) {
      console.error("Failed to submit score:", err);
      throw err;
    }
  };

  return {
    submit,
  };
};

export const useIsFollowing = (request: UserFollowRequest) => {
  const { data, error } = useSWR(
    `user-follow-find-${request.followingUserId}`,
    () => UserAPI.following(request),
    { refreshInterval: APP_CONSTANTS.REFETCH_INTERVAL_TIME }
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
};
