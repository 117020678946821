"use client";

import useSWR from "swr";
import { ImageAPI, ImageShowRequest } from "@/restapi/image";
import APP_CONSTANTS from "@/constants/AppConstants";

const useImageById = (request: ImageShowRequest) => {
  const { data: item, error } = useSWR(
    request.imageUuid ? `show-${request.imageUuid}` : null,
    () => ImageAPI.show(request),
    { refreshInterval: APP_CONSTANTS.REFETCH_INTERVAL_TIME }
  );

  return {
    item,
    isLoading: !error && !item,
    isError: error,
  };
};

export { useImageById };
