"use client";

import { mutate } from "swr";
import { UserAPI, UserIconUpdateRequest } from "@/restapi/user";

export const useUserIconUpdate = () => {
  const submitUserIconUpdate = async (
    userIconUpdateRequest: UserIconUpdateRequest
  ) => {
    try {
      // APIにPOSTリクエストを送信してデータを取得
      const restData = await UserAPI.iconUpdate(userIconUpdateRequest);

      // キャッシュを更新
      mutate(
        `user-icon-update-${JSON.stringify(userIconUpdateRequest)}`,
        restData,
        false
      );

      return restData;
    } catch (err) {
      console.error("Failed to submit user icon:", err);
      throw err;
    }
  };

  return {
    submitUserIconUpdate,
  };
};
